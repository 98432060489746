.live-indicator {
  font-family: 'Roboto', sans-serif;
  background: #ea2429;
  color: #fff;
  padding: 6px 7px 6px 18px;
  line-height: 1;
  border-radius: 6px;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-weight: bold;
  width: auto;
  position: relative;
}

.live-indicator > .blink {
  animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
  font-size: 15px;
  vertical-align: baseline;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-60%);
  left: 11px;
  font-style: normal;
}

@keyframes blinker {
  from { opacity: 1; }
  to { opacity: 0; }
}